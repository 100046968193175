import { jwtDecode } from "jwt-decode";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AppState,
  Auth0Provider,
  User as Auth0User,
  useAuth0,
} from "@auth0/auth0-react";

import { getCurrentTenant } from "../lib/auth";

import config from "../config";

import { DebugModes, User, UserAuthMetadata } from "../lib/auth";

const defaultAuthContext = {
  allowedTenants: [] as string[],
  authStatus: "unauthenticated",
  currentTenant: "",
  currentUser: {
    id: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    createdAt: 0,
    loginCount: 0,
    authMetadata: {
      service_access: [],
      user_permission: [],
    },
  } as User,
  getDebug: () => "unauthorized" as DebugModes,
  setCurrentTenant: (tenant: string) => {},
  toggleDebug: () => {},
};

const AuthContext = React.createContext<typeof defaultAuthContext | null>(null);

const InnerAuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { getAccessTokenSilently, isAuthenticated, user, logout } = useAuth0();
  const [authState, setAuthState] = React.useState({
    allowedTenants: defaultAuthContext.allowedTenants,
    currentTenant:
      localStorage.getItem("tenant") ?? defaultAuthContext.currentTenant,
    debugAuthorized: false,
    authMetadata: defaultAuthContext.currentUser.authMetadata,
    authStatus: defaultAuthContext.authStatus,
  });

  const setCurrentTenant = React.useCallback((tenant: string) => {
    setAuthState((prevAuthState) =>
      prevAuthState.allowedTenants.includes(tenant)
        ? {
            ...prevAuthState,
            currentTenant: tenant,
          }
        : prevAuthState
    );
    localStorage.setItem("tenant", tenant);
    window.location.reload();
  }, []);

  const getDebug = React.useCallback((): DebugModes => {
    if (!authState.debugAuthorized) return "unauthorized";

    if (localStorage.getItem("debug")) {
      return "enabled";
    }
    return "disabled";
  }, [authState.debugAuthorized]);

  const toggleDebug = React.useCallback(() => {
    if (localStorage.getItem("debug")) {
      localStorage.removeItem("debug");
    } else {
      localStorage.setItem("debug", "1");
    }
  }, []);

  React.useEffect(() => {
    if (!isAuthenticated) return;

    setAuthState((prevAuthState) => ({
      ...prevAuthState,
      authStatus: "loading",
    }));
    getAccessTokenSilently({
      authorizationParams: {
        audience: config.services.restApi.basePath,
      },
    })
      .then((encodedAccessToken) => {
        const decodedAccessToken = jwtDecode(encodedAccessToken);
        if (!(decodedAccessToken as any).cirrus_permission) {
          logout({
            logoutParams: { returnTo: window.location.href },
          });
        }
        const allowedTenants = Object.keys(
          (decodedAccessToken as any).cirrus_permission[config.environmentName]
        ) as string[];
        const currentTenant = getCurrentTenant(allowedTenants);
        /**
         * If tenant selection stored in the localStorage is not match to the currentTenant
         * then invoke setCurrentTenant.
         * This can be happen under the scenario below.
         * 1. User assigned to the tenant1 and tenant2 role and select tenant1 to use (that store tenant1 in localStorage).
         * 2. User is removed the role on the tenant1 on Auth0.
         * 3. Re-login and now token only has role on tenant2 but localStorage pointing to tenant1.
         */
        const storedTenant = localStorage.getItem("tenant");
        if (storedTenant !== currentTenant) {
          setCurrentTenant(currentTenant);
        }
        const userPermissions = (decodedAccessToken as any).cirrus_permission[
          config.environmentName
        ][currentTenant] as UserAuthMetadata;

        setAuthState((prevAuthState) => ({
          ...prevAuthState,
          allowedTenants,
          debugAuthorized:
            (decodedAccessToken as any).cirrus_permission.global?.debug ??
            false,
          authMetadata: userPermissions,
          authStatus: "ready",
        }));
      })
      .catch((e: Error) => {
        // Especially try to catch exception like
        // isAuthenticated is true but refresh token is already expired
        logout({
          logoutParams: { returnTo: window.location.href },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  let userEmail: string;
  let userId: string;
  let userFirstName: string;
  let userLastName: string;

  if (isAuthenticated) {
    userEmail = (user as Auth0User).email as string;
    userId = ((user as Auth0User).sub as string).slice(6);
    userFirstName = (user as Auth0User).given_name as string;
    userLastName = (user as Auth0User).family_name as string;
  } else {
    userEmail = "";
    userId = "";
    userFirstName = "";
    userLastName = "";
  }
  const value = React.useMemo(
    () => ({
      allowedTenants: authState.allowedTenants,
      authStatus: authState.authStatus,
      currentTenant: authState.currentTenant,
      currentUser: {
        id: userId,
        firstName: userFirstName,
        lastName: userLastName,
        emailAddress: userEmail,
        createdAt: 0,
        loginCount: 0,
        authMetadata: authState.authMetadata,
      },
      setCurrentTenant,
      getDebug,
      toggleDebug,
    }),
    [
      authState.allowedTenants,
      authState.authStatus,
      authState.currentTenant,
      authState.authMetadata,
      userId,
      userFirstName,
      userLastName,
      userEmail,
      setCurrentTenant,
      getDebug,
      toggleDebug,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();

  // On login, redirect to the same page the user was at before
  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo ?? window.location.href);
  };

  return (
    <Auth0Provider
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: config.services.restApi.basePath,
      }}
      cacheLocation="localstorage"
      clientId={config.services.auth0.clientId}
      domain={config.services.auth0.domain}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
    >
      <InnerAuthProvider>{children}</InnerAuthProvider>
    </Auth0Provider>
  );
};

export const useAuth = () => {
  const authContext = React.useContext(AuthContext);

  if (!authContext) {
    throw new Error("useAuth must be used inside the AuthProvider");
  }
  return authContext;
};
