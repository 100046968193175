import React from "react";

const Radio = (
  props: React.DetailedHTMLProps<
    React.FieldsetHTMLAttributes<HTMLFieldSetElement>,
    HTMLFieldSetElement
  > & {
    changed?: boolean;
    label?: string;
    name: string;
    onChange: (value: string) => void;
    options: { label?: string; value: string }[];
    required?: boolean;
    value: string;
  }
) => {
  const { changed, label, name, onChange, options, required, value, ...rest } =
    props;

  return (
    <>
      {label ? (
        <label>
          {label}
          {required ? <span className="urgent"> *</span> : null}
        </label>
      ) : null}
      <fieldset className="radio-wrapper" {...rest}>
        {options.map((option) => (
          <label htmlFor={option.value} key={option.value}>
            <input
              checked={option.value === value}
              className={
                option.value === value && changed ? "changed" : undefined
              }
              id={option.value}
              key={option.value}
              name={name}
              onChange={(e) => onChange(e.target.value)}
              tabIndex={0}
              type="radio"
              value={option.value}
            />
            <div className="radio-button" />
            <div className="radio-button-label">
              {option.label ?? option.value}
            </div>
          </label>
        ))}
      </fieldset>
    </>
  );
};
export default Radio;
