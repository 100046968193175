import React from "react";

import config, { serviceBasePaths } from "../config";

export type CirrusRole = "Guest" | "Standard" | "Administrator" | "Custom";
export type DebugModes = "disabled" | "enabled" | "unauthorized";
export type ServiceAccessLevels =
  | "full access to "
  | "no access to "
  | "read-only access to all non-sensitive data in ";
export type ServiceNames =
  | "dashboards" // Superset
  | "data_dictionary" // Secoda
  | "data_repository" // Snowflake
  | "v2n_monitoring" // Grafana
  | "web_client:device_management"
  | "web_client:time_travel"
  | "web_client:user_management";
export type PermissionNames =
  | "roadside_devices:admin" // allow access full access to the device related information.
  | "roadside_devices:read" // allow read access to the device related information not including sensitive data.
  | "users:admin" // allow full access to the user related information.
  | "vehicle_devices:admin" // allow access full access to the device related information.
  | "vehicle_devices:read" // allow read access to the device related information not including sensitive data.
  | "v2x_messages:admin" // allow any access to the v2x related message including sensitive information.
  | "v2x_messages:read"; // allow read access to the v2x related message not including sensitive information.
export type UserAuthMetadata = {
  service_access: ServiceNames[];
  user_permission: PermissionNames[];
};
export type User = {
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  createdAt: number;
  loginCount: number;
  authMetadata: UserAuthMetadata;
};
export type UserRole = {
  name: string;
  permissions: UserAuthMetadata;
};

export const getCurrentTenant = (allowedTenants: string[]) => {
  const storedTenant = localStorage.getItem("tenant");

  if (allowedTenants.includes(storedTenant as string)) {
    return storedTenant as string;
  }
  return allowedTenants[0];
};

export const roles: UserRole[] = [
  {
    name: "Guest",
    permissions: {
      service_access: ["data_dictionary", "data_repository"],
      user_permission: [
        "roadside_devices:read",
        "vehicle_devices:read",
        "v2x_messages:read",
      ],
    },
  },
  {
    name: "Standard",
    permissions: {
      service_access: [
        "dashboards",
        "data_dictionary",
        "data_repository",
        "web_client:device_management",
        "web_client:time_travel",
      ],
      user_permission: [
        "roadside_devices:admin",
        "vehicle_devices:admin",
        "v2x_messages:admin",
      ],
    },
  },
  {
    name: "Administrator",
    permissions: {
      service_access: [
        "dashboards",
        "data_dictionary",
        "data_repository",
        "web_client:device_management",
        "web_client:time_travel",
        "web_client:user_management",
      ],
      user_permission: [
        "roadside_devices:admin",
        "vehicle_devices:admin",
        "v2x_messages:admin",
        "users:admin",
      ],
    },
  },
];

const roleMatch = (uam: UserAuthMetadata, r: UserRole) => {
  if (
    uam.service_access.length !== r.permissions.service_access.length ||
    uam.user_permission.length !== r.permissions.user_permission.length
  ) {
    return false;
  }
  if (
    !uam.service_access.every((servAcc) =>
      r.permissions.service_access.includes(servAcc)
    )
  ) {
    return false;
  }
  return uam.user_permission.every((userPerm) =>
    r.permissions.user_permission.includes(userPerm)
  );
};

export const getPageTitle = (pathname: string) => {
  if (pathname === "/") {
    return "Home";
  } else if (
    pathname.startsWith(serviceBasePaths["web_client:device_management"])
  ) {
    return "Device Manager";
  } else if (pathname.startsWith(serviceBasePaths["web_client:time_travel"])) {
    return "Time Travel";
  } else if (
    pathname.startsWith(serviceBasePaths["web_client:user_management"])
  ) {
    return "Users";
  }
  return "Not Found";
};

export const getRoleName = (uam: UserAuthMetadata) => {
  for (const role of roles) {
    if (roleMatch(uam, role)) {
      return role.name;
    }
  }
  return "Custom";
};

export const getTenantName = (tenant: string) => {
  if ((config.tenants as any)[tenant]?.name) {
    return (config.tenants as any)[tenant].name;
  }
  return tenant;
};

const linkStyle = { border: "none", padding: 0, color: "#42a5f5" };

const canAccessFixedDeviceData = (uam: UserAuthMetadata) =>
  uam.user_permission.includes("roadside_devices:admin") ||
  uam.user_permission.includes("roadside_devices:read");

const canAccessVehicleDeviceData = (uam: UserAuthMetadata) =>
  uam.user_permission.includes("vehicle_devices:admin") ||
  uam.user_permission.includes("vehicle_devices:read");

const canAccessV2XMessages = (uam: UserAuthMetadata) =>
  uam.user_permission.includes("v2x_messages:admin") ||
  uam.user_permission.includes("v2x_messages:read");

const canAccessAllDataLimited = (uam: UserAuthMetadata) =>
  canAccessFixedDeviceData(uam) &&
  canAccessVehicleDeviceData(uam) &&
  canAccessV2XMessages(uam);

const canAccessAllDataFull = (uam: UserAuthMetadata) =>
  uam.user_permission.includes("roadside_devices:admin") &&
  uam.user_permission.includes("vehicle_devices:admin") &&
  uam.user_permission.includes("v2x_messages:admin");

export const getUserServiceAccess = (
  user: UserAuthMetadata,
  service: ServiceNames
): ServiceAccessLevels => {
  if (!user.service_access.includes(service)) {
    return "no access to ";
  }

  switch (service) {
    case "dashboards":
    case "data_repository":
    case "web_client:time_travel":
      if (canAccessAllDataLimited(user)) {
        if (canAccessAllDataFull(user)) {
          return "full access to ";
        }
        return "read-only access to all non-sensitive data in ";
      }
      return "no access to ";
    case "data_dictionary":
    case "v2n_monitoring":
      return "full access to ";
    case "web_client:device_management":
      if (user.user_permission.includes("roadside_devices:admin")) {
        return "full access to ";
      }
      return "no access to ";
    case "web_client:user_management":
      if (user.user_permission.includes("users:admin")) {
        return "full access to ";
      }
      return "no access to ";
  }
};

export const canAccessService = (
  user: UserAuthMetadata,
  service: ServiceNames
) => getUserServiceAccess(user, service) !== "no access to ";

export const getRoleDescription = (
  uam: UserAuthMetadata,
  debugEnabled: boolean,
  prefix: string
) => {
  const services = [
    {
      id: "dashboards",
      label: "Dashboards",
    },
    {
      id: "data_dictionary",
      label: "Data Dictionary",
    },
    {
      id: "data_repository",
      label: "Data Repository",
    },
    {
      id: "v2n_monitoring",
      label: "V2N Monitoring",
    },
    {
      id: "web_client:device_management",
      label: "Device Manager",
    },
    {
      id: "web_client:time_travel",
      label: "Time Travel",
    },
    {
      id: "web_client:user_management",
      label: "User Manager",
    },
  ];

  return (
    <ul style={{ listStyleType: "none" }}>
      {services
        .filter((service) => debugEnabled || service.id !== "v2n_monitoring")
        .map((service) => (
          <li key={service.id}>
            <span>
              {prefix} {getUserServiceAccess(uam, service.id as ServiceNames)}
              {(serviceBasePaths as any)[service.id] ? (
                <a
                  href={
                    service.id === "dashboards"
                      ? "https://dashboards.cirrus.panasonic.com/dashboard/list"
                      : (serviceBasePaths as any)[service.id]
                  }
                  style={linkStyle}
                >
                  {service.label}
                </a>
              ) : (
                service.label
              )}
              .
            </span>
          </li>
        ))}
    </ul>
  );
};
