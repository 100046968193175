import config from "../config";

import { generateRandomInteger } from "./generate";

import {
  FixedDevice,
  NonNormalizedSite,
  NormalizedSite,
  SiteType,
} from "./devices/deviceTypes";
import { JSONValue } from "./json";

export const API_ABORT_MESSAGE =
  "Request aborted before response was received.";

export const callRestApi = async <T extends {}>({
  accessToken,
  body,
  method = "get",
  path,
  signal,
}: {
  accessToken: string;
  body?: JSONValue;
  method?: "get" | "post";
  path: string;
  signal: AbortSignal;
}) => {
  try {
    const url = `${config.services.restApi.basePath}${path}`;
    const response = await fetch(url, {
      body: method === "get" ? undefined : JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method,
      mode: "cors",
      signal,
    });

    if (!response.ok) {
      throw new Error(`HTTP response status ${response.status}`);
    }
    const responseJson = (await response.json()) as T;

    return responseJson;
  } catch (error) {
    const message = error instanceof Error ? error.message : error;

    if (message === API_ABORT_MESSAGE) {
      console.warn(message);
    } else {
      console.error(message);
    }
    throw message;
  }
};

const fetchAllDevices = async ({
  accessToken,
  currentTenant,
  signal,
}: {
  accessToken: string;
  currentTenant: string;
  signal: AbortSignal;
}) => {
  const pageSize = 500;
  const devicesResponse = await callRestApi<{
    devices: FixedDevice[];
    snapshotIntervalMax: number;
    count: number;
  }>({
    accessToken,
    path: `/devices?tenant=${currentTenant}&limit=${pageSize}`,
    signal,
  });

  const fetchPromises = [];
  for (
    let offset = pageSize;
    offset < devicesResponse.count;
    offset += pageSize
  ) {
    const devicesResponsePromise = callRestApi<{
      devices: FixedDevice[];
      snapshotIntervalMax: number;
    }>({
      accessToken,
      path: `/devices?tenant=${currentTenant}&offset=${offset}&limit=${pageSize}`,
      signal,
    });
    fetchPromises.push(devicesResponsePromise);
  }

  return await fetchPromises.reduce(
    async (deviceResponsePromise, nextFetchPromise) => {
      const { devices: totalDevices } = await deviceResponsePromise;
      const { devices: moreDevices, snapshotIntervalMax } =
        await nextFetchPromise;
      return {
        devices: [...totalDevices, ...moreDevices],
        snapshotIntervalMax: snapshotIntervalMax,
      };
    },
    Promise.resolve(devicesResponse)
  );
};

export const fetchAssets = async ({
  accessToken,
  currentTenant,
  signal,
}: {
  accessToken: string;
  currentTenant: string;
  signal: AbortSignal;
}) => {
  const [devicesResponse, sitesResponse] = await Promise.all([
    fetchAllDevices({
      accessToken,
      currentTenant,
      signal,
    }),
    callRestApi<{
      sites: NonNormalizedSite[];
      snapshotIntervalMax: number;
    }>({
      accessToken,
      path: `/sites?tenant=${currentTenant}`,
      signal,
    }),
  ]);

  const devices = devicesResponse.devices
    .sort((deviceA, deviceB) => deviceB.changedAt - deviceA.changedAt)
    .reduce((acc, device) => {
      return {
        ...acc,
        [device.id]: device,
      };
    }, {} as { [deviceId: string]: FixedDevice });
  const devicesLookup = Object.values(devices).reduce((acc, device) => {
    acc[device.id] = device.id;

    if (device.siteId !== null) {
      acc[device.siteId] = device.id;
    }

    if (device.ipAddress) {
      acc[device.ipAddress] = device.id;
    }
    return acc;
  }, {} as { [deviceKey: string]: string });

  const sites = sitesResponse.sites
    .filter(
      (site) =>
        ![
          "04f585f99a4bf109281bef15b65a6ffb",
          "0a4406fe4aec1b90ec18ca859ef90c69",
          "2c056865fdb1d95cb97909cafa9f46c6",
          "3387f4904d8903f2d9ab48601bfaab9f",
          "3749acc277d475c96931528b4521b3a7",
          "38498d1575e55c948045836e2e88c172",
          "426f47c8e02592377f628b27796b7c11",
          "4ab9053f0ae78540d2809178a1e2fe3d",
          "4ad2b9e281b7c491ff5eded084ddbf32",
          "58d876088ad80ba405850cb063c2b1c2",
          "6db19e32adb0f304ad3ad6fd0f4caefc",
          "7669d0679380da7e45b8d4fae65a7819",
          "878b491c3fdb0c0451099ccd7456075d",
          "b2d8cdf656d6cedaff6c888671c1a54f",
          "b35e328291d3c59909f5c86b6961b3f3",
          "bec63fcb83af3bb47cc82a0561fe853d",
          "e0511109e0430ae10e7b9f5cdcc868ff",
          "e83472a8154dbdc590ef0d7848bdf816",
          "ed44388d208cedbc849f8e1ea525218f",
          "f5b3bb986c97d8053163520d6dc4a474",
          "f5f7dc9dd41477b82000dfb5de8e3659",
        ].includes(site.id)
    )
    .map((site) => ({
      ...site,
      deviceIds: devicesResponse.devices
        .filter((device) => device.siteId === site.id)
        .map((device) => device.id),
    }))
    .sort((siteA, siteB) => {
      const latestSiteADevice = siteA.deviceIds
        .map((deviceId) => devices[devicesLookup[deviceId]])
        .sort((deviceA, deviceB) => deviceB.changedAt - deviceA.changedAt)[0];
      const latestSiteBDevice = siteB.deviceIds
        .map((deviceId) => devices[devicesLookup[deviceId]])
        .sort((deviceA, deviceB) => deviceB.changedAt - deviceA.changedAt)[0];

      if (latestSiteADevice) {
        if (latestSiteBDevice) {
          return latestSiteADevice.changedAt > latestSiteBDevice.changedAt
            ? -1
            : 1;
        }
        return -1;
      } else if (latestSiteBDevice) {
        return 1;
      }
      return 0;
    })
    .reduce((acc, site) => {
      return {
        ...acc,
        [site.id]: {
          deviceIds: site.deviceIds,
          id: site.id,
          intersectionId: site.intersectionId,
          lat: site.lat,
          lon: site.lon,
          name: site.name,
          city: site.city,
          roadRegulatorId: site.roadRegulatorId,
          siteMetadata: {
            gateway: null,
            project_name: null,
            road_regulator_id: site.roadRegulatorId,
            subnet: null,
          } as any,
          type: (typeof site.intersectionId === "number"
            ? "Intersection"
            : "Standalone") as SiteType,
        },
      };
    }, {} as { [siteId: string]: NormalizedSite });
  const sitesLookup = Object.values(sites).reduce((acc, site) => {
    acc[site.id] = site.id;

    if (site.intersectionId !== null) {
      if (site.roadRegulatorId !== null) {
        acc[`${site.intersectionId}:${site.roadRegulatorId}`] = site.id;
      } else {
        acc[site.intersectionId] = site.id;
      }
    }
    return acc;
  }, {} as { [siteKey: string]: string });

  return {
    devices,
    devicesLookup,
    sites,
    sitesLookup,
    snapshotIntervalMax: devicesResponse.snapshotIntervalMax,
  };
};

export const registerNewSite = async ({
  accessToken,
  currentTenant,
  signal,
  site,
}: {
  accessToken: string;
  currentTenant: string;
  signal: AbortSignal;
  site: Partial<NonNormalizedSite>;
}) => {
  const body = { ...site };
  delete (body as any).deviceIds;
  delete body.id;
  delete (body as any).type;

  await callRestApi({
    accessToken,
    body,
    method: "post",
    path: `/sites?tenant=${currentTenant}`,
    signal,
  });
};

export const editSite = async ({
  accessToken,
  currentTenant,
  signal,
  site,
}: {
  accessToken: string;
  currentTenant: string;
  signal: AbortSignal;
  site: Partial<NonNormalizedSite>;
}) => {
  const body = { ...site };
  delete (body as any).deviceIds;
  delete body.id;
  delete (body as any).type;

  await callRestApi({
    accessToken,
    body,
    method: "post",
    path: `/sites/${site.id}?tenant=${currentTenant}`,
    signal,
  });
};

export const registerNewDevice = async ({
  accessToken,
  currentTenant,
  device,
  signal,
}: {
  accessToken: string;
  currentTenant: string;
  device: Partial<FixedDevice>;
  signal: AbortSignal;
}) => {
  const body = { ...device };
  delete body.id;

  await callRestApi({
    accessToken,
    body,
    method: "post",
    path: `/devices?tenant=${currentTenant}`,
    signal,
  });
};

export const editDevice = async ({
  accessToken,
  currentTenant,
  device,
  signal,
}: {
  accessToken: string;
  currentTenant: string;
  device: Partial<FixedDevice>;
  signal: AbortSignal;
}) => {
  const body = { ...device };
  delete body.id;

  await callRestApi({
    accessToken,
    body,
    method: "post",
    path: `/devices/${device.id}?tenant=${currentTenant}`,
    signal,
  });
};

export const removeDevice = async ({
  accessToken,
  currentTenant,
  device,
  signal,
}: {
  accessToken: string;
  currentTenant: string;
  device: FixedDevice;
  signal: AbortSignal;
}) => {
  return new Promise((resolve, reject) =>
    setTimeout(
      () =>
        generateRandomInteger(1, 6) > 3
          ? resolve(null)
          : reject(new Error("API error")),
      1000
    )
  );
};
