import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useSearchParams } from "react-router-dom";

import AddOrEditUserModal, {
  RemoveUserModal,
} from "../userManager/UserManagerModals";
import UsersListPage from "../userManager/UsersListPage";
import UserProfilePage from "../userManager/UserProfilePage";

import { API_ABORT_MESSAGE, callRestApi } from "../../lib/api";
import { useAuth } from "../../state/authState";
import { useUI } from "../../state/uiState";

import config from "../../config";

import { User } from "../../lib/auth";

const UsersPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentTenant } = useAuth();
  const [searchParams] = useSearchParams();
  const { currentOverlay } = useUI();

  const [dataState, setDataState] = React.useState({
    loading: false,
    users: [] as User[],
  });

  const userIdParam = searchParams.get("u") ?? "";

  const upsertUser = React.useCallback((newUser: User) => {
    if (newUser.id === "add") return;

    setDataState((prevDataState) => {
      const prevUsers = [...prevDataState.users];
      const newUserIndex = prevUsers.findIndex(
        (user) => user.id === newUser.id
      );

      if (newUserIndex !== -1) {
        prevUsers.splice(newUserIndex, 1);
      }
      prevUsers.unshift(newUser);

      return {
        ...prevDataState,
        users: prevUsers,
      };
    });
  }, []);

  React.useEffect(() => {
    const abortController = new AbortController();

    const loadUserManagerData = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.services.restApi.basePath,
        },
      });

      try {
        const usersResponse = await callRestApi<User[]>({
          accessToken,
          path: `/users?tenant=${currentTenant}`,
          signal: abortController.signal,
        });
        setDataState({ loading: false, users: usersResponse });
      } catch (error) {
        if (error === API_ABORT_MESSAGE) {
          console.warn(error);
        } else {
          console.error(error);
        }
      }
    };

    loadUserManagerData();

    return () => {
      abortController.abort(API_ABORT_MESSAGE);
    };
  }, [getAccessTokenSilently, currentTenant]);

  return (
    <>
      {currentOverlay === "addUser" ? (
        <AddOrEditUserModal
          mode="add"
          showConfirmationScreen={!userIdParam}
          user={{
            id: "new",
            emailAddress: "",
            firstName: "",
            lastName: "",
            createdAt: 0,
            loginCount: 0,
            authMetadata: {
              service_access: ["data_dictionary", "data_repository"],
              user_permission: [
                "roadside_devices:read",
                "vehicle_devices:read",
                "v2x_messages:read",
              ],
            },
          }}
          upsertUser={upsertUser}
        />
      ) : null}
      {currentOverlay.startsWith("editUser:") ? (
        <AddOrEditUserModal
          mode="edit"
          showConfirmationScreen={!userIdParam}
          user={
            dataState.users.find(
              (user) => user.id === currentOverlay.split(":")[1]
            ) as User
          }
          upsertUser={upsertUser}
        />
      ) : null}
      {currentOverlay.startsWith("removeUser:") ? (
        <RemoveUserModal
          user={
            dataState.users.find(
              (user) => user.id === currentOverlay.split(":")[1]
            ) as User
          }
          removeUser={() => {
            setDataState((prevDataState) => {
              const prevUsers = [...prevDataState.users];
              const prevUserIndex = prevUsers.findIndex(
                (user) => user.id === currentOverlay.split(":")[1]
              );

              if (prevUserIndex !== -1) {
                prevUsers.splice(prevUserIndex, 1);
              }
              return {
                ...prevDataState,
                users: prevUsers,
              };
            });
          }}
        />
      ) : null}
      {userIdParam ? (
        <UserProfilePage
          loading={!dataState.users.length}
          user={dataState.users.find((u) => u.id === userIdParam) as User}
        />
      ) : (
        <UsersListPage loading={dataState.loading} users={dataState.users} />
      )}
    </>
  );
};
export default UsersPage;
