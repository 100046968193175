import React from "react";

import { useUI } from "../state/uiState";

import Button from "./controls/Button";

const Modal = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    footer?: React.ReactNode;
    header?: React.ReactNode;
  }
) => {
  const { setOverlay } = useUI();

  const { children, className: providedClassName, ...rest } = props;
  let className = "modal";

  if (typeof providedClassName === "string") {
    className += ` ${providedClassName}`;
  }
  return (
    <div className={className} {...rest}>
      <div className="modal-header">
        {props.header}
        <Button
          onClick={() => setOverlay("")}
          style={{ position: "absolute", top: 26, right: 22 }}
          title="Close modal"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
              fill="black"
            />
          </svg>
        </Button>
      </div>
      <div className="modal-body">{children}</div>
      <div className="modal-footer">{props.footer}</div>
    </div>
  );
};
export default Modal;
