import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import config, { serviceBasePaths } from "./config";

import HomePage from "./components/pages/HomePage";
import DeviceManagerPage from "./components/pages/DeviceManagerPage";
import TimeTravelPage from "./components/pages/TimeTravelPage";
import UsersPage from "./components/pages/UsersPage";
import NotFoundPage from "./components/pages/NotFoundPage";
import { canAccessService } from "./lib/auth";
import { AuthProvider } from "./state/authState";
import { MapProvider } from "./state/mapState";
import { UIProvider, useUI } from "./state/uiState";

import Header from "./components/Header";
import AuthGuard from "./components/AuthGuard";

import "./App.css";

const AlertBox = () => {
  const { alerts } = useUI();

  return (
    <div id="alert-box">
      {alerts.map((alert) => (
        <div
          key={alert.key}
          className={`alert ${alert.severity}${alert.fading ? " fading" : ""}`}
        >
          {alert.message}
        </div>
      ))}
    </div>
  );
};

const Clickaway = () => {
  const { currentOverlay, setOverlay } = useUI();

  return currentOverlay ? (
    <div id="clickaway" onClick={() => setOverlay("")} />
  ) : null;
};

function App() {
  return (
    <ThemeProvider theme={config.theme}>
      <BrowserRouter>
        <AuthProvider>
          <MapProvider>
            <UIProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <AlertBox />
                <Clickaway />
                <Header />
                <main>
                  <Routes>
                    <Route
                      path="/"
                      element={<AuthGuard component={HomePage} />}
                    />
                    <Route
                      path={serviceBasePaths["web_client:device_management"]}
                      element={
                        <AuthGuard
                          authorize={(uam) =>
                            canAccessService(
                              uam,
                              "web_client:device_management"
                            )
                          }
                          component={DeviceManagerPage as any}
                        />
                      }
                    />
                    <Route
                      path={serviceBasePaths["web_client:time_travel"]}
                      element={
                        <AuthGuard
                          authorize={(uam) =>
                            canAccessService(uam, "web_client:time_travel")
                          }
                          component={TimeTravelPage as any}
                        />
                      }
                    />
                    <Route
                      path={serviceBasePaths["web_client:user_management"]}
                      element={<AuthGuard component={UsersPage as any} />}
                    />
                    <Route
                      path="*"
                      element={<AuthGuard component={NotFoundPage as any} />}
                    />
                  </Routes>
                </main>
              </LocalizationProvider>
            </UIProvider>
          </MapProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
